import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles/index';
import Typography from '@material-ui/core/Typography/index';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import frFlag from './images/frFlag.svg';
import gbFlag from './images/gbFlag.svg';
import unescoLandscape from './images/unescoLandscape.svg';
import unescoPortrait from './images/unescoPortrait.svg';
import colors from '../../helpers/colors';

const styles = () => ({
  root: {
    height: '100%',
  },
  shape: {
    maxWidth: '90%',
    maxHeight: '60vh',
    margin: 'auto',
    marginTop: 20,
    marginBottom: 20,
    '@media (orientation: landscape)': {
      maxHeight: '70vh',
    },
  },
  shapePortrait: {
    display: 'none',
    '@media (orientation: portrait)': {
      display: 'block',
    },
  },
  shapeLandscape: {
    display: 'none',
    '@media (orientation: landscape)': {
      display: 'block',
    },
  },
  bottom: {
    '@media (orientation: landscape)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  choose: {
    lineHeight: 1.2,
    fontSize: '1rem',
    '@media (orientation: landscape)': {
      fontSize: '1.25rem',
      textAlign: 'right',
      paddingRight: 20,
      borderRight: `1px solid ${colors.grey}`,
    },
  },
  buttons: {
    '@media (orientation: landscape)': {
      textAlign: 'left',
      paddingLeft: 20,
    },
  },
  flags: {
    width: '30%',
    maxWidth: 100,
    margin: '2%',
    display: 'inline-block',
    cursor: 'pointer',
    '@media (orientation: landscape)': {
      width: '35%',
      margin: '0 5%',
    },
  },
  flagsImg: {
    width: '100%',
  },
});

const StyledGrid = withStyles({
  container: {
    minHeight: '100%',
    textAlign: 'center',
  },
})(Grid);

class Homepage extends Component {
  goToNexStep() {
    this.props.history.push('/videos');
  }

  async chooseLanguage(lang) {
    this.props.setLanguage(lang);
    this.goToNexStep();
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <StyledGrid container justify="center" alignItems="center">
          <Grid item xs={12}>
            <img src={unescoLandscape} className={`${classes.shape} ${classes.shapeLandscape}`} alt="Welcome to a dicussion between students from all over the world" />
            <img src={unescoPortrait} className={`${classes.shape} ${classes.shapePortrait}`} alt="Welcome to a dicussion between students from all over the world" />
          </Grid>
          <Grid item xs={12} className={classes.bottom}>
            <Typography variant="h6" className={classes.choose}>
              <strong>Choose</strong> your language
              <br />
              <strong>Sélectionnez</strong> votre langue
            </Typography>
            <div className={classes.buttons}>
              <div
                role="button"
                onClick={() => this.chooseLanguage('fr')}
                onKeyPress={() => {
                }}
                tabIndex="0"
                className={classes.flags}
              >
                <img src={frFlag} className={classes.flagsImg} alt="Français" />
              </div>
              <div
                role="button"
                onClick={() => this.chooseLanguage('en')}
                onKeyPress={() => {
                }}
                tabIndex="0"
                className={classes.flags}
              >
                <img src={gbFlag} className={classes.flagsImg} alt="English" />
              </div>
            </div>
          </Grid>
        </StyledGrid>
      </div>
    );
  }
}

Homepage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  setLanguage: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    shape: PropTypes.string.isRequired,
    buttons: PropTypes.string.isRequired,
    shapeLandscape: PropTypes.string.isRequired,
    shapePortrait: PropTypes.string.isRequired,
    flags: PropTypes.string.isRequired,
    flagsImg: PropTypes.string.isRequired,
    choose: PropTypes.string.isRequired,
    bottom: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(withStyles(styles)(Homepage));
