export const getClientLocales = (client) => {
  if (['besancon', 'pmf'].includes(client)) {
    return ['fr'];
  }

  return ['fr', 'en'];
};

export const setDefaultStoreDataForClient = (defaultStoreData) => {
  if (window.location.toString().includes('unesco')) {
    defaultStoreData.config.clientName = 'unesco';
  }

  if (window.location.toString().includes('besancon')) {
    defaultStoreData.config.clientName = 'besancon';
  }

  if (window.location.toString().includes('pmf') || window.location.toString().includes('morris')) {
    defaultStoreData.config.clientName = 'pmf';
  }

  const clientLanguages = getClientLocales(defaultStoreData.config.clientName);
  if (clientLanguages.length === 1) {
    defaultStoreData.config.language = clientLanguages[0];
  }

  return defaultStoreData;
};
