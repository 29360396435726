import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles/index';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import pmfLandscape from './images/pmfLandscape.svg';
import pmfPortrait from './images/pmfPortrait.svg';
import colors from '../../helpers/colors';

const styles = () => ({
  root: {
    height: '100%',
  },
  shape: {
    maxWidth: '90%',
    maxHeight: '60vh',
    width: '100%',
    margin: 'auto',
    marginTop: 20,
    marginBottom: 20,
  },
  shapePortrait: {
    display: 'none',
    '@media (orientation: portrait)': {
      display: 'block',
    },
  },
  shapeLandscape: {
    display: 'none',
    '@media (orientation: landscape)': {
      display: 'block',
    },
  },
  bottom: {
    '@media (orientation: landscape)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  button: {
    border: `2px solid ${colors.black}`,
    borderRadius: '50%',
    height: 'min(145px, 30vw)',
    width: 'min(145px, 30vw)',
    fontSize: 'min(32px, 6vw)',
    fontWeight: 'bold',
    margin: '4vh 0',
    '@media (orientation: portrait)': {
      margin: '10vh 0',
    },
  },
});

const StyledGrid = withStyles({
  container: {
    minHeight: '100%',
    textAlign: 'center',
  },
})(Grid);

class Homepage extends Component {
  goToNexStep() {
    this.props.history.push('/videos');
  }

  async chooseLanguage(lang) {
    this.props.setLanguage(lang);
    this.goToNexStep();
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <StyledGrid container justify="center" alignItems="center">
          <Grid item xs={12}>
            <img src={pmfLandscape} className={`${classes.shape} ${classes.shapeLandscape}`} alt="Bienvenue dans la boîte à questions Philip Morris France" />
            <img src={pmfPortrait} className={`${classes.shape} ${classes.shapePortrait}`} alt="Bienvenue dans la boîte à questions Philip Morris France" />
          </Grid>
          <Grid item xs={12} className={classes.bottom}>
            <Button
              onClick={() => this.chooseLanguage('fr')}
              onKeyPress={() => {
              }}
              tabIndex="0"
              className={classes.button}
            >
              entrer
            </Button>
          </Grid>
        </StyledGrid>
      </div>
    );
  }
}

Homepage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  setLanguage: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    shape: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    shapeLandscape: PropTypes.string.isRequired,
    shapePortrait: PropTypes.string.isRequired,
    bottom: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(withStyles(styles)(Homepage));
