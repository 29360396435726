import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import NextButton from '../BottomButtons/NextButton';
import logo from './images/logo.svg';
import logoPmf from './images/logoPmf.svg';

// video and recording events are dispatched in component
const activityEvents = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart', 'video', 'recording'];
const IDLE_TIMEOUT = 150; // 2min30s

class LayoutWithTitle extends Component {
  lastUserActionDate = new Date();

  constructor(props) {
    super(props);

    this.state = {
      confirmOpen: false,
      secondsBeforeFinish: IDLE_TIMEOUT,
      finishTimeoutId: null,
    };
  }

  componentDidMount() {
    if (!this.props.useIdleTimer) {
      return;
    }

    this.setIdleListener();
    this.changeFinishInterval();
  }

  componentWillUnmount() {
    if (!this.props.useIdleTimer) {
      return;
    }

    this.removeIdleListener();
    if (this.state.finishTimeoutId !== null) {
      clearTimeout(this.state.finishTimeoutId);
    }
  }

  resetIdleTimer = () => {
    this.lastUserActionDate = new Date();
  };

  setIdleListener() {
    activityEvents.forEach(eventName => document.addEventListener(eventName, this.resetIdleTimer, true));
  }

  removeIdleListener() {
    activityEvents.forEach(eventName => document.removeEventListener(eventName, this.resetIdleTimer, true));
  }

  handleClick() {
    if (!this.props.userHasStartedToAnswer || (this.props.sendFormStatus && this.props.sendFormStatus.isFinished)) {
      this.props.history.push('/');
      return;
    }
    this.setState({ confirmOpen: true });
  }

  handleClose() {
    this.setState({ confirmOpen: false });
  }

  changeFinishInterval() {
    let secondsBeforeFinish = IDLE_TIMEOUT;

    const secondsSinceLastEvent = Math.ceil((new Date() - this.lastUserActionDate) / 1000);
    secondsBeforeFinish -= secondsSinceLastEvent;

    if (secondsBeforeFinish <= 0) {
      this.props.history.push('/');
      return;
    }

    const finishTimeoutId = setTimeout(() => this.changeFinishInterval(), 1000);
    this.setState({ secondsBeforeFinish, finishTimeoutId });
  }

  render() {
    const { children, config } = this.props;
    const { secondsBeforeFinish } = this.state;
    // Can't use replaceAll on old safari
    const page = this.props.history.location.pathname.replace(/\//g, '');
    let pageLogo = logo;
    if (config?.clientName === 'pmf') {
      pageLogo = logoPmf;
    }

    return (
      <section className="titled-content" data-page={page}>
        <div className="WithResponsivePadding">
          <span onClick={() => this.handleClick()} onKeyPress={() => this.handleClick()} role="button" tabIndex={0}>
            <img src={pageLogo} alt="20 Questions to the World" className="logo" />
          </span>
          {this.state.secondsBeforeFinish < 30 && <NextButton className="IdleTimer"><Trans i18nKey="layout.backToHomeIn" values={{ secondsBeforeFinish }} /></NextButton>}
        </div>
        <div className="WithResponsivePadding">
          {children}
        </div>

        <Dialog
          open={this.state.confirmOpen}
          onClose={() => this.handleClose()}
          PaperProps={{ style: { backgroundColor: 'white' } }}
        >
          <DialogTitle><Trans i18nKey="layout.cancelTitle" /></DialogTitle>
          <DialogContent>
            <DialogContentText><Trans i18nKey="layout.cancelText" /></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose()}><Trans i18nKey="layout.cancelCancel" /></Button>
            <Button component={Link} to="/" autoFocus><Trans i18nKey="layout.cancelConfirm" /></Button>
          </DialogActions>
        </Dialog>
      </section>
    );
  }
}

LayoutWithTitle.defaultProps = {
  useIdleTimer: false,
};

LayoutWithTitle.propTypes = {
  config: PropTypes.shape({
    clientName: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
  userHasStartedToAnswer: PropTypes.bool.isRequired,
  useIdleTimer: PropTypes.bool,
  sendFormStatus: PropTypes.shape({
    isFinished: PropTypes.bool,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withRouter(LayoutWithTitle);
