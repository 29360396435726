import { connect } from 'react-redux';
import { setLanguage } from '../actions';
import HomepageUnesco from '../../components/Homepage/HomepageUnesco';

const mapDispatchToProps = dispatch => ({
  setLanguage: value => dispatch(setLanguage(value)),
});

const HomepageContainer = connect(
  null,
  mapDispatchToProps,
)(HomepageUnesco);

export default HomepageContainer;
