import { connect } from 'react-redux';
import { setLanguage } from '../actions';
import HomepageBesancon from '../../components/Homepage/HomepageBesancon';

const mapDispatchToProps = dispatch => ({
  setLanguage: value => dispatch(setLanguage(value)),
});

const HomepageContainer = connect(
  null,
  mapDispatchToProps,
)(HomepageBesancon);

export default HomepageContainer;
