import { connect } from 'react-redux';
import { setVideoQuestion } from '../actions';
import AskNewQuestion from '../../components/UserRecord/AskNewQuestion';

const mapStateToProps = state => ({
  config: state.config,
});

const mapDispatchToProps = dispatch => ({
  resetVideoQuestion: () => dispatch(setVideoQuestion(null)),
});

const AskNewQuestionContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AskNewQuestion);

export default AskNewQuestionContainer;
