import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { Snackbar, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import {
  BigPlayButton, ControlBar, Player, Shortcut,
} from 'video-react';
import Typography from '@material-ui/core/Typography/Typography';
import colors from '../../helpers/colors';
import ButtonsContainer from '../BottomButtons/ButtonsContainer';
import PreviousButton from '../BottomButtons/PreviousButton';
import NextButton from '../BottomButtons/NextButton';
import { fetchBlobAndGetUrl } from '../../helpers/helpers';

const classStyles = () => ({
  dropzone: {
    position: 'relative',
    minHeight: 'calc(min(100vh, 100vw, 1080px) * 9 / 16)',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: colors.black,
    outline: 'none',
    transition: 'border .24s ease-in-out',
    border: '12px solid black',
    borderLeftColor: colors.blue,
    borderTopColor: colors.green,
    borderRightColor: colors.yellow,
    borderBottomColor: colors.red,
  },
});

const styles = {
  content: {
    position: 'relative',
  },
  uploadButtonIcon: {
    width: '10vw',
    height: '10vw',
    maxWidth: 100,
    maxHeight: 100,
  },
  uploadButton: {
    margin: 'auto',
    width: 'min(200px, 18vw)',
    height: 'min(200px, 18vw)',
    // There is an issue with the Helvetica font on FF: the font is not vertically centered
    padding: 0,
    minWidth: 0,
    minHeight: 0,
    maxWidth: 250,
    maxHeight: 250,
    backgroundColor: `${colors.white} !important`,
    boxShadow: 'none',
    border: `2px solid ${colors.red}`,
    color: colors.red,
    fontSize: '3.6vw',
    borderRadius: '15vw',
    fontWeight: 'bold',
  },
  instructions: {
    padding: 10,
  },
  alert: {
    background: colors.red,
    color: colors.white,
    fontWeight: 'bold',
    padding: '10px 40px',
    textAlign: 'center',
    whiteSpace: 'pre-line',
    zIndex: 1,
  },
  gotoEnd: {
    fontSize: '.95rem',
    paddingTop: 10,
    paddingBottom: 10,
  },
};

const minSize = 1000; // 1KB
const maxSize = 300 * 1000 * 1000; // 300MB
const mimeTypes = ['video/mp4', 'video/x-m4v', 'video/webm', 'video/*'];
const UploadButton = withStyles(theme => ({ root: { ...styles.uploadButton, [theme.breakpoints.up('md')]: { fontSize: 40 } } }))(Button);

class VideoUploader extends Component {
  constructor(props) {
    super(props);

    this.replayAllowed = true;
    this.state = {
      uploadedVideoBlobUrl: null,
      uploadedVideoFilename: null,
      error: null,
    };
  }

  componentDidMount() {
    if (this.props.defaultVideoUrl) {
      fetchBlobAndGetUrl(this.props.defaultVideoUrl)
        .then((videoBlobUrl) => this.setState({ uploadedVideoBlobUrl: videoBlobUrl }));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.uploadedVideoBlobUrl && this.state.uploadedVideoBlobUrl !== prevState.uploadedVideoBlobUrl) {
      URL.revokeObjectURL(prevState.uploadedVideoBlobUrl);
    }
  }

  validateRecord = async () => {
    if (!this.props.onValidate || !this.state.uploadedVideoBlobUrl) {
      return;
    }

    this.props.onValidate(this.state.uploadedVideoBlobUrl, this.state.uploadedVideoFilename);
  }

  handleChange = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      const errors = rejectedFiles[0]?.errors || [];
      // code: "file-too-large" | "file-too-small" | "too-many-files" | "file-invalid-type" | string;
      const errorMessage = errors.map(({ code, message }) => this.props.t(`videoUploader.errors.${code}`) || message).join('\n');

      this.setState({ error: errorMessage || this.props.t('videoUploader.error') });
      return;
    }

    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      this.setState({ uploadedVideoBlobUrl: URL.createObjectURL(file), uploadedVideoFilename: file.name });
    }
  }

  render() {
    const { classes } = this.props;

    if (this.state.uploadedVideoBlobUrl) {
      return (
        <div style={styles.content} className="video-finished">
          <Player
            playsInline
            aspectRatio="16:9"
            muted={false}
            autoPlay={false}
            ref={this.playerRef}
            src={this.state.uploadedVideoBlobUrl}
          >
            <ControlBar autoHide={false} disableCompletely={!this.replayAllowed} />
            <BigPlayButton position="center" />
            <Shortcut disabled={!this.replayAllowed} />
          </Player>

          <Typography style={styles.gotoEnd}>
            <Trans i18nKey="videoAnswer.gotToEnd" />
            <span className="green-square" />
          </Typography>

          <ButtonsContainer>
            <PreviousButton onClick={() => this.setState({ uploadedVideoBlobUrl: null })}>
              <Trans i18nKey="videoRecorder.restartRecording" />
            </PreviousButton>
            <NextButton onClick={this.validateRecord}>
              <Trans i18nKey="videoRecorder.validateRecord" />
            </NextButton>
          </ButtonsContainer>
        </div>
      );
    }

    return (
      <div style={styles.content}>
        <Dropzone onDrop={this.handleChange} accept={mimeTypes} multiple={false} maxSize={maxSize} minSize={minSize}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ className: classes.dropzone })}>
              <input {...getInputProps()} capture="user" />
              <UploadButton color="secondary" size="large" variant="contained" aria-label="Upload">
                <CameraAltIcon fontSize="large" titleAccess="Upload" style={styles.uploadButtonIcon} />
              </UploadButton>
            </div>
          )}
        </Dropzone>
        <Snackbar open={!!this.state.error} autoHideDuration={10000} onClose={() => this.setState({ error: null })}>
          <div style={styles.alert}>{this.state.error}</div>
        </Snackbar>
      </div>
    );
  }
}

VideoUploader.defaultProps = {
  onValidate: null,
  defaultVideoUrl: null,
};

VideoUploader.propTypes = {
  onValidate: PropTypes.func,
  defaultVideoUrl: PropTypes.string,
  t: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    dropzone: PropTypes.string.isRequired,
  }).isRequired,
};

export default withTranslation()(withStyles(classStyles)(VideoUploader));
