export default {
  white: '#fff',
  grey: '#aaa',
  greyLight: '#eee',
  greyLighter: '#fafafa',
  black: '#000',
  blue: '#009cfc',
  green: '#00e27b',
  red: '#ff2a00',
  pink: '#ffdcd2',
  yellow: '#ffec00',
};
