import Button from '@material-ui/core/Button/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ButtonsContainer from '../BottomButtons/ButtonsContainer';
import NextButton from '../BottomButtons/NextButton';
import PreviousButton from '../BottomButtons/PreviousButton';
import endpageShape from './images/endpageShape.svg';
import facebookImg from './images/facebook.svg';
import instagramImg from './images/instagram.svg';
import youtubeImg from './images/youtube.svg';
import './UserRecord.css';

const styles = {
  socialImgRoot: {
    marginTop: '5%',
  },
  socialImg: {
    height: 'min(7vw, 5vh)',
    maxHeight: 60,
    marginRight: '7%',
  },
  shape: {
    width: '95%',
    maxWidth: '50vh',
    marginBottom: 20,
  },
  circularProgress: {
    marginLeft: 20,
    marginRight: 20,
  },
  brand: {
    fontSize: 'min(4vh, 4vw)',
  },
  recordId: {
    fontSize: 'min(3vh, 3vw)',
    marginTop: '4vh',
    marginBottom: '2vh',
  },
};

const StyledGrid = withStyles({
  container: {
    marginTop: '6vh',
    marginBottom: '6vh',
  },
})(Grid);

const StyledGridItem = withStyles({
  root: {
    '@media (orientation: landscape)': {
      maxWidth: '50%',
      flexBasis: '50%',
    },
  },
})(Grid);

const IDLE_TIMEOUT = 15;

class SendForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      secondsBeforeFinish: IDLE_TIMEOUT,
      finishTimeoutId: null,
    };
  }

  componentDidMount() {
    if (this.props.userHasAnswered) {
      this.triggerSend();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.sendFormStatus.isFinished && !prevProps.sendFormStatus.isFinished && this.props.useIdleTimer) {
      this.setFinishInterval(this.state.secondsBeforeFinish);
    }
  }

  componentWillUnmount() {
    if (this.state.finishTimeoutId !== null) {
      clearTimeout(this.state.finishTimeoutId);
    }
  }

  setFinishInterval(seconds) {
    const secondsBeforeFinish = parseInt(seconds || 0, 10);

    if (secondsBeforeFinish <= 0) {
      this.finish();
      return;
    }

    const finishTimeoutId = setTimeout(() => this.setFinishInterval(secondsBeforeFinish - 1), 1000);
    this.setState({ secondsBeforeFinish, finishTimeoutId });
  }

  triggerSend() {
    this.props.sendFormAnswers();
  }

  finish() {
    this.props.resetUserRecord();
    this.props.history.push('/');
  }

  render() {
    const { userHasAnswered, history } = this.props;
    const status = this.props.sendFormStatus;

    if (!userHasAnswered) {
      return (
        <>
          <Typography variant="h4" className="margin-v-large"><Trans i18nKey="sendForm.errorEmptyForm" /></Typography>
          <ButtonsContainer>
            <PreviousButton onClick={() => history.push('/')}>
              <strong><Trans i18nKey="sendForm.returnToTheBeginning" /></strong>
            </PreviousButton>
          </ButtonsContainer>
        </>
      );
    }

    if (typeof status.isFinished === 'undefined' || status.isSending) {
      return (
        <>
          <Typography variant="h2" className="margin-v-large">
            <CircularProgress size={30} style={styles.circularProgress} />
            <Trans i18nKey="sendForm.sending" />
            {status.progress !== undefined && <span> ({ status.progress }%)</span>}
          </Typography>
        </>
      );
    }

    if (typeof status.error === 'string' || typeof status.error?.message !== 'undefined') {
      return (
        <>
          <Typography variant="h2" className="margin-v-large">
            <Trans i18nKey="sendForm.error" /> {status.error.message || status.error}
          </Typography>
          <ButtonsContainer>
            <NextButton onClick={() => this.triggerSend()}>
              <strong><Trans i18nKey="sendForm.retry" /></strong>
            </NextButton>
          </ButtonsContainer>
        </>
      );
    }

    if (status.isFinished) {
      return (
        <>
          <Typography variant="h2" gutterBottom className="big">
            <strong><Trans i18nKey="sendForm.endTitle" /></strong>
          </Typography>
          <Typography variant="h3"><Trans i18nKey="sendForm.endDescription" /></Typography>

          <StyledGrid container>
            <StyledGridItem item xs={8}>
              <img src={endpageShape} style={styles.shape} alt="" />
            </StyledGridItem>
            <StyledGridItem item xs={12}>
              <Typography variant="h4" gutterBottom style={styles.brand}>
                @20<strong>questions</strong>to<strong>the</strong>world<br />
              </Typography>

              <div style={styles.socialImgRoot}>
                <a href="https://www.instagram.com/20questionstotheworld/" target="_blank" rel="noreferrer">
                  <img src={instagramImg} alt="instagram" style={styles.socialImg} />
                </a>
                <a href="https://www.facebook.com/20questionstotheworld" target="_blank" rel="noreferrer">
                  <img src={facebookImg} alt="facebook" style={styles.socialImg} />
                </a>
                <a href="https://www.youtube.com/channel/UCVxjZOlPyCdvKZ05InPdcTA" target="_blank" rel="noreferrer">
                  <img src={youtubeImg} alt="youtube" style={styles.socialImg} />
                </a>
              </div>
            </StyledGridItem>
          </StyledGrid>

          {false && status.data && status.data.uploadName && (
          <>
            <Typography gutterBottom style={styles.recordId}><em><Trans i18nKey="sendForm.recordId" /> {status.data.uploadName}.</em></Typography>
          </>
          )}

          <ButtonsContainer sendFormPage>
            <NextButton onClick={() => this.finish()}>
              <strong>
                <Trans i18nKey="sendForm.returnToTheBeginning" />
                {this.state.secondsBeforeFinish !== IDLE_TIMEOUT && (
                <>({this.state.secondsBeforeFinish})</>
                )}
              </strong>
            </NextButton>
          </ButtonsContainer>
        </>
      );
    }

    return (
      <>
        <Typography variant="h2" className="margin-v-large"><Trans i18nKey="sendForm.errorWhenSending" /></Typography>
        <Button variant="contained" color="secondary" size="large" onClick={() => this.triggerSend()}><Trans i18nKey="sendForm.retry" /></Button>
      </>
    );
  }
}

SendForm.defaultProps = {
  sendFormStatus: {},
  useIdleTimer: false,
};

SendForm.propTypes = {
  useIdleTimer: PropTypes.bool,
  userHasAnswered: PropTypes.bool.isRequired,
  sendFormStatus: PropTypes.shape({
    isSending: PropTypes.bool,
    progress: PropTypes.number,
    isFinished: PropTypes.bool,
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
    data: PropTypes.shape({
      uploadName: PropTypes.string,
    }),
  }),
  sendFormAnswers: PropTypes.func.isRequired,
  resetUserRecord: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(SendForm);
