import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import 'typeface-roboto';
import colors from '../../helpers/colors';
import './Layout.css';

const theme = createMuiTheme({
  typography: {
    // Caution: there is an issue with Helvetica font on Linux
    // https://stackoverflow.com/questions/45985699/how-to-fix-mis-aligned-characters-in-linux-osx-firefox-when-using-helvetica
    fontFamily: 'Helvetica, "Helvetica Neue", Roboto, Arial, sans-serif',
    useNextVariants: true,
    h1: {
      lineHeight: 1.2,
    },
    h2: {
      lineHeight: 1.1,
    },
  },
  palette: {
    primary: {
      main: colors.green,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.black,
      contrastText: colors.white,
    },
    background: colors.white,
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
  },
});

theme.overrides.MuiTypography = {
  h1: {
    fontSize: '8vw',
    '@media (orientation: landscape)': {
      fontSize: '9vh',
    },
  },
  h2: {
    fontSize: '6vw',
    '@media (orientation: landscape)': {
      fontSize: '7vh',
    },
  },
  h3: {
    fontSize: '5vw',
    '@media (orientation: landscape)': {
      fontSize: '6vh',
    },
  },
  h4: {
    fontSize: '4vw',
    '@media (orientation: landscape)': {
      fontSize: '5vh',
    },
  },
};

theme.overrides.MuiPaper = {
  root: {
    background: 'white',
  },
};

function Layout({ children, withTitle }) {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <section className={`main ${withTitle ? 'with-title' : ''}`}>
        {children}
      </section>
    </MuiThemeProvider>
  );
}

Layout.defaultProps = {
  withTitle: false,
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  withTitle: PropTypes.bool,
};

export default Layout;
