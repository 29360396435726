import { connect } from 'react-redux';
import { setLanguage } from '../actions';
import HomepagePmf from '../../components/Homepage/HomepagePmf';

const mapDispatchToProps = dispatch => ({
  setLanguage: value => dispatch(setLanguage(value)),
});

const HomepageContainer = connect(
  null,
  mapDispatchToProps,
)(HomepagePmf);

export default HomepageContainer;
