import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography/Typography';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import RecordRTC from 'recordrtc';
import {
  BigPlayButton, ControlBar, Player, Shortcut,
} from 'video-react';
import { cameraAction, sendCameraRequest } from '../../helpers/cameraClient';
import colors from '../../helpers/colors';
import { calcDuration } from '../../helpers/date';
import {
  fetchBlobAndGetUrl, mobileDetect, setStatePromise, getSeekableBlob, sendErrorToSentry,
} from '../../helpers/helpers';
import ButtonsContainer from '../BottomButtons/ButtonsContainer';
import NextButton from '../BottomButtons/NextButton';
import PreviousButton from '../BottomButtons/PreviousButton';
import DownloadButton from './DownloadButton';
import './VideoRecorder.css';

const hasGetUserMedia = !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);

const classStyles = () => ({
  videoContainer: {
    position: 'relative',
    minHeight: 'calc(100vw * 9 / 16)',
    '@media (orientation: landscape)': {
      minHeight: 'calc(max(100vh, 600px) * 9 / 16)',
    },
  },
});

const styles = {
  content: {
    position: 'relative',
  },
  startButton: {
    position: 'absolute',
    right: 0,
    left: 0,
    top: 'calc(50% - min(100px, 9vw))',
    margin: 'auto',
    width: 'min(200px, 18vw)',
    height: 'min(200px, 18vw)',
    // There is an issue with the Helvetica font on FF: the font is not vertically centered
    padding: 0,
    minWidth: 0,
    minHeight: 0,
    maxWidth: 250,
    maxHeight: 250,
    backgroundColor: `${colors.red} !important`,
    color: colors.white,
    fontSize: '3.6vw',
    borderRadius: '15vw',
    fontWeight: 'bold',
  },
  startButtonTimer: {
    fontSize: '5.5vw',
  },
  stopButton: {
    position: 'absolute',
    bottom: '6%',
    right: '4%',
    backgroundColor: colors.red,
    boxShadow: 'none',
    color: colors.white,
    borderRadius: 0,
    fontWeight: 'bold',
    padding: '3.5% 4%',
    fontSize: 20,
    minWidth: 0,
  },
  duration: {
    position: 'absolute',
    top: '1%',
    left: '2%',
    color: colors.black,
    fontWeight: 'bold',
    fontSize: 25,
  },
  loading: {
    position: 'absolute',
    bottom: '5%',
    left: '2%',
    color: colors.black,
    fontWeight: 'bold',
    fontSize: 25,
  },
  durationDot: {
    color: colors.red,
    fontSize: 40,
    paddingRight: 10,
    top: 3,
    position: 'relative',
  },
  error: {
    border: `10px solid ${colors.red}`,
    padding: 20,
    color: colors.red,
    margin: '40px 0',
  },
  gotoEnd: {
    fontSize: '.95rem',
    paddingTop: 10,
    paddingBottom: 10,
  },
};

const StartButton = withStyles(theme => ({ root: { ...styles.startButton, [theme.breakpoints.up('md')]: { fontSize: 40 } } }))(Button);

const TimerStartButton = withStyles(theme => ({ root: { ...styles.startButton, ...styles.startButtonTimer, [theme.breakpoints.up('lg')]: { fontSize: 100, lineHeight: '100px' } } }))(Button);

class VideoRecorder extends Component {
  constructor(props) {
    super(props);

    // TODO Use a dedicated config option, and use it here and for VideoUploader
    // props.config.videoRecorderChoice !== 'recorder' <==> borne
    this.replayAllowed = props.config.videoRecorderChoice !== 'recorder';

    this.playerRef = React.createRef();
    this.matchPortraitQuery = window.matchMedia('(orientation: portrait)');

    this.state = {
      error: null,
      recorder: null,
      stream: null,
      recordedVideoBlobUrl: null,
      recordedVideoCameraFilename: null,
      isRecording: false,
      recordingStartDate: null, // eslint-disable-line react/no-unused-state
      recordingDuration: '',
      isPortrait: this.matchPortraitQuery.matches,
      isLoading: false,
      startTimer: null, // int | null
      fistClickDone: window.fistClickDone,
    };

    this.onMediaOrientationChange = this.onMediaOrientationChange.bind(this);

    window.onerror = (msg) => {
      if (!/Chrome/.test(navigator.userAgent) || !/Google Inc/.test(navigator.vendor)) {
        // Display error in a popup if not chrome
        alert(`Error: ${msg}`); // eslint-disable-line no-alert
      }

      return true;
    };
  }

  componentDidMount() {
    if (!hasGetUserMedia) {
      let message = this.props.t('videoRecorder.noUserMediaUnsupported');
      if (window.location.protocol === 'http:') {
        message = this.props.t('videoRecorder.noUserMediaHttp');
      }

      this.displayError(this.props.t('videoRecorder.noUserMedia', { message }));
      return;
    }

    this.matchPortraitQuery.addListener(this.onMediaOrientationChange);

    this.onMount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.stream && !prevState.stream) {
      this.displayVideoStream(this.state.stream);
    }

    if (prevState.recordedVideoBlobUrl && this.state.recordedVideoBlobUrl !== prevState.recordedVideoBlobUrl) {
      URL.revokeObjectURL(prevState.recordedVideoBlobUrl);
    }

    if (this.state.recordedVideoBlobUrl && !prevState.recordedVideoBlobUrl) {
      this.displayVideoStream(null);
    }

    if (this.state.isPortrait !== prevState.isPortrait) {
      this.changeStreamOrientation(this.state.stream);
    }

    if (this.props.config !== prevProps.config) {
      this.resetPlayer();
    }
  }

  componentWillUnmount() {
    if (this.state.stream && this.state.stream.active) {
      this.stopStream();
    }

    if (this.state.recorder) {
      this.state.recorder.destroy();
    }

    this.matchPortraitQuery.removeListener(this.onMediaOrientationChange);
    this.sendCameraRequest(cameraAction.RESET);
  }

  async onMount() {
    if (this.props.defaultVideoUrl) {
      await fetchBlobAndGetUrl(this.props.defaultVideoUrl)
        .then((videoBlobUrl) => {
          this.setState({ recordedVideoBlobUrl: videoBlobUrl });
        })
        .catch(() => this.requestUserMedia());
    } else {
      await this.requestUserMedia();
    }

    this.sendCameraRequest(cameraAction.RESET_RECORDING, true);
  }

  onMediaOrientationChange(mql) {
    this.setState({ isPortrait: mql.matches });
  }

  getWantedVideoRatio() {
    if (!mobileDetect.mobile()) {
      return 16 / 9;
    }

    return this.state.isPortrait ? 9 / 16 : 16 / 9;
  }

  changeStreamOrientation(stream) {
    if (!stream || !stream.getVideoTracks().length) return;

    const videoTrack = stream.getVideoTracks()[0];

    // Not supported on FF
    if (typeof videoTrack.getCapabilities === 'undefined') {
      console.warn('VideoTrack capabilities is not supported on this device');
      return;
    }

    const minRatio = videoTrack.getCapabilities().aspectRatio.min;
    const constraints = videoTrack.getConstraints();

    // Safari on iOS
    if (!constraints?.aspectRatio) {
      console.warn('VideoTrack constraints is not supported on this device');
      return;
    }

    constraints.aspectRatio.exact = Math.max(minRatio, this.getWantedVideoRatio());
    videoTrack.applyConstraints(constraints).catch(e => {
      sendErrorToSentry(e);
    });
  }

  async sendCameraRequest(action, ignoreError = false, useLoadingState = true) {
    if (!this.props.config.sendCameraCommands) return Promise.resolve();

    if (useLoadingState) {
      await setStatePromise(this, { isLoading: true });
    }

    try {
      const response = await sendCameraRequest(action);
      await setStatePromise(this, { isLoading: false });
      return response;
    } catch (e) {
      if (ignoreError) {
        return null;
      }

      this.sendCameraRequest(cameraAction.RESET, true);
      await setStatePromise(this, { isLoading: false, error: this.props.t('videoRecorder.cameraError', { message: e.message || JSON.stringify(e) }) });
    }

    return null;
  }

  async requestUserMedia() {
    await this.sendCameraRequest(cameraAction.START_PREVIEW);

    if (this.props.config.sendCameraCommands) {
      await new Promise(resolve => setTimeout(resolve, 1000));
    }

    // Wait for a first click on the page, as we cannot ask for userMedia if no action has been made in Chrome
    await new Promise((resolve) => {
      const resolveIfFirstClickDone = () => {
        if (this.state.fistClickDone) {
          resolve();
        } else {
          setTimeout(resolveIfFirstClickDone, 100);
        }
      };

      resolveIfFirstClickDone();
    });

    let width = {
      min: 640,
      max: 1920, // FHD
      // max: 3840, // 4k
      ideal: 1920,
    };

    if (this.props.config.videoIdealWidth) {
      width = { exact: parseInt(this.props.config.videoIdealWidth, 10) };
    }

    // If nothing happen here, it's maybe because there is no input video stream.
    // You must select the good video device in the /about page, and turn on your camera on video mode

    const constraints = {
      audio: {
        deviceId: this.props.config.audioDeviceId,
      },
      video: {
        deviceId: this.props.config.videoDeviceId,
        width,
        aspectRatio: {
          exact: 16 / 9, // This value can be overrided in changeStreamOrientation for portrait views
        },
        frameRate: { ideal: 30 },
      },
    };

    console.info('MediaStreamConstraints', constraints);

    return navigator.mediaDevices.getUserMedia(constraints)
      .then((stream) => {
        this.props.setLastMediaPermissionAllowDate();
        this.changeStreamOrientation(stream);

        return setStatePromise(this, { stream });
      })
      .catch(this.displayError);
  }

  displayError = (e) => {
    if (!e) {
      return;
    }
    sendErrorToSentry(e);
    let error = e.message || e.name || JSON.stringify(e);

    if (this.props.t(`videoRecorder.${e.name}`) !== `videoRecorder.${e.name}`) {
      error = this.props.t(`videoRecorder.${e.name}`);
    }

    this.setState({ error });
  }

  getPromisedPlayer = () => {
    let tries = 0;
    const maxTries = 10;
    return new Promise((resolve, reject) => {
      const resolveIfDefined = () => {
        if (tries >= maxTries) {
          reject(new Error('Unable to find the player in less than 1 second'));
          return;
        }
        if (this.playerRef.current && this.playerRef.current.video) {
          resolve(this.playerRef.current);
        } else {
          tries += 1;
          setTimeout(resolveIfDefined, 100);
        }
      };

      resolveIfDefined();
    });
  }

  async displayVideoStream(stream) {
    try {
      const player = await this.getPromisedPlayer();
      player.video.video.srcObject = stream;
      player.load();
    } catch (e) {
      if (stream === null) {
        // The player has been removed
        return;
      }
      throw e;
    }
  }

  resetPlayer() {
    if (this.state.stream && this.state.stream.active) {
      this.stopStream();
    }

    if (this.state.recorder) {
      this.state.recorder.destroy();
    }

    return setStatePromise(this, {
      recorder: null,
      error: null,
      isRecording: false,
      recordedVideoBlobUrl: null,
      recordedVideoCameraFilename: null,
      stream: null,
    }).then(() => this.requestUserMedia());
  }

  async startRecord() {
    if (!this.state.stream || !this.state.stream.active) {
      await this.requestUserMedia();
    }

    try {
      await this.startRecording();
    } catch (e) {
      this.displayError(e);
    }
  }

  getMimeType() {
    const defaultType = this.props.config.videoMimeType;

    if (defaultType && window.MediaRecorder && window.MediaRecorder.isTypeSupported(defaultType)) {
      return defaultType;
    }

    if (typeof window.MediaRecorder === 'undefined') return 'video/webm';

    // Return the best mime type in terms of performance
    const types = [
      'video/webm;codecs=h264', // Chrome on Desktop
      'video/webm;codecs=vp8,opus', // FF > 74, Chrome android
      'video/webm;codecs=vp8',
      'video/webm;codecs=vp9,opus',
      'video/webm;codecs=vp9',
      'video/mp4', // Safari
    ];

    for (let i = 0; i < types.length; i += 1) {
      if (window.MediaRecorder.isTypeSupported(types[i])) {
        return types[i];
      }
    }

    return 'video/webm';
  }

  async startRecording() {
    const setRecorder = (state) => {
      if (state.recorder) {
        state.recorder.destroy();
      }

      const recorder = RecordRTC(state.stream, {
        type: 'video',

        mimeType: this.getMimeType(),
        disableLogs: false,

        audioBitsPerSecond: parseFloat(this.props.config.audioBitsPerSecond || 128 * 1000), // 128 kbits/s
        videoBitsPerSecond: parseFloat(this.props.config.videoBitsPerSecond || 30 * 1000 * 1000), // 30 Mbits/s
      });

      return {
        recorder, recordedVideoBlobUrl: null, recordedVideoCameraFilename: null, isRecording: true, recordingStartDate: new Date(), startTimer: null,
      };
    };

    const cameraPromise = this.sendCameraRequest(cameraAction.START_RECORDING);
    await this.createPromisedStartTimer();
    await cameraPromise;

    this.setState(setRecorder, () => {
      this.updateRecordDuration();
      this.state.recorder.startRecording();
    });
  }

  setStartTimer(seconds, resolve) {
    const secondsBeforeFinish = parseInt(seconds || 0, 10);

    if (secondsBeforeFinish <= 0) {
      resolve();
      return;
    }

    setTimeout(() => this.setStartTimer(secondsBeforeFinish - 1, resolve), 1000);
    this.setState({ startTimer: secondsBeforeFinish });
  }

  createPromisedStartTimer() {
    return new Promise((resolve) => {
      this.setStartTimer(3, resolve);
    });
  }

  saveBlob = (blob) => {
    const url = URL.createObjectURL(blob);

    this.setState({
      stream: null, recordedVideoBlobUrl: url, isRecording: false, recordingStartDate: null, // eslint-disable-line react/no-unused-state
    });
  }

  stopRecord() {
    this.state.recorder.stopRecording(() => {
      this.stopStream();

      // Fix video seeking issues except for Apple browsers
      getSeekableBlob(this.state.recorder.getBlob())
        .then(this.saveBlob)
        .catch(this.displayError);
    });

    this.sendCameraRequest(cameraAction.STOP_RECORDING).then((movieFilename) => {
      this.setState({ recordedVideoCameraFilename: movieFilename });
      this.sendCameraRequest(cameraAction.STOP_PREVIEW, true, false);
    });
  }

  stopStream() {
    const tracks = this.state.stream.getTracks();
    tracks.forEach((track) => {
      track.stop();
    });
  }

  downloadRecord() {
    const mime = this.getMimeType();
    const ext = mime.includes('webm') ? 'webm' : 'mp4';
    this.state.recorder.save(`video.${ext}`);
  }

  validateRecord() {
    if (!this.props.onValidate) {
      return;
    }

    this.props.onValidate(this.state.recordedVideoBlobUrl, this.state.recordedVideoCameraFilename);
  }

  updateRecordDuration() {
    if (!this.state.isRecording) {
      this.setState({ recordingDuration: null });
      return;
    }

    document.dispatchEvent(new Event('recording')); // Send event for idle timer reset

    this.setState(state => ({ recordingDuration: calcDuration(state.recordingStartDate, new Date()) }), () => {
      setTimeout(() => this.updateRecordDuration(), 1000);
    });
  }

  render() {
    const { classes } = this.props;
    const isPreview = !!this.state.recordedVideoBlobUrl;

    if (this.state.error) {
      return (
        <div style={styles.content}>
          <div style={styles.error}>
            <Typography variant="h5" color="error">
              <Trans i18nKey="videoRecorder.error" /> - {this.state.error}
            </Typography>
            {this.props.config.videoRecorderChoice !== 'recorder' && (
              <>
                <br />
                <Typography variant="body2"><Trans i18nKey="videoRecorder.useUploader" /></Typography>
                <Button onClick={() => this.props.setVideoRecorderChoice('uploader_default')} type="button" variant="outlined" color="default">
                  <Trans i18nKey="videoUploaderChoice.useUploaderButton" />
                </Button>
              </>
            )}
          </div>

          <ButtonsContainer>
            <PreviousButton onClick={() => this.resetPlayer()}>
              <Trans i18nKey="videoRecorder.restartRecording" />
            </PreviousButton>
          </ButtonsContainer>
        </div>
      );
    }

    if (!this.state.fistClickDone) {
      return (
        <div style={styles.content} className={classes.videoContainer}>
          <StartButton color="secondary" size="large" variant="contained" onClick={() => this.setState({ fistClickDone: true })} aria-label="Start">
            <Trans i18nKey="videoRecorder.begin" />
          </StartButton>
        </div>
      );
    }

    if (!this.state.stream && !isPreview) {
      return (
        <div style={styles.content} className={classes.videoContainer}>
          <div className="loader-container">
            <div className="blink-loader">...</div>
          </div>
        </div>
      );
    }

    return (
      <div style={styles.content} className={classes.videoContainer + (this.replayAllowed ? '' : ' video-react-readonly')}>

        {this.props.config.videoTransform && (
          <style type="text/css">{`video.video-react-video { transform: ${this.props.config.videoTransform}; }`}</style>
        )}

        {isPreview && (
        <Player
          playsInline
          aspectRatio="16:9"
        >
          <source src={this.state.recordedVideoBlobUrl} />
          <ControlBar autoHide={false} disableCompletely={!this.replayAllowed}>
            <DownloadButton order={7} onClick={() => this.downloadRecord()} />
          </ControlBar>
          <BigPlayButton position="center" />
          <Shortcut disabled={!this.replayAllowed} />
        </Player>
        )}

        {!isPreview && (
          <Player
            playsInline
            aspectRatio="16:9"
            muted
            autoPlay
            ref={this.playerRef}
          >
            <ControlBar disableCompletely />
            <BigPlayButton position="center" />
            <Shortcut disabled />
          </Player>
        )}

        {!this.state.isRecording && !isPreview && this.state.startTimer === null && (
          <StartButton color="secondary" size="large" variant="contained" onClick={() => this.startRecord()} aria-label="Start record" disabled={this.state.isLoading}>
            <Trans i18nKey="videoRecorder.start" />
          </StartButton>
        )}

        {this.state.startTimer !== null && (
          <TimerStartButton color="secondary" size="large" variant="contained" aria-label="Start timer">
            {this.state.startTimer}
          </TimerStartButton>
        )}

        {this.state.isRecording && (
          <Button color="primary" size="large" variant="contained" style={styles.stopButton} onClick={() => this.stopRecord()} aria-label="Stop record">
            <Trans i18nKey="videoRecorder.stop" />
          </Button>
        )}

        {this.state.isLoading && (
          <Typography style={styles.loading}>
            <span className="blink" style={styles.durationDot}>...</span>
          </Typography>
        )}

        {this.state.isRecording && (
          <Typography style={styles.duration}>
            <span className="blink" style={styles.durationDot}>&#9679;</span>
            {this.state.recordingDuration}
          </Typography>
        )}

        {!this.state.isRecording && isPreview && (
          <>
            <Typography style={styles.gotoEnd}>
              <Trans i18nKey="videoAnswer.gotToEnd" />
              <span className="green-square green-square-end" />
            </Typography>
            <ButtonsContainer>
              <PreviousButton onClick={() => this.resetPlayer()} disabled={this.state.isLoading}>
                <Trans i18nKey="videoRecorder.restartRecording" />
              </PreviousButton>
              <NextButton onClick={() => this.validateRecord()} disabled={this.state.isLoading}>
                <Trans i18nKey="videoRecorder.validateRecord" />
              </NextButton>
            </ButtonsContainer>
          </>
        )}
      </div>
    );
  }
}

VideoRecorder.defaultProps = {
  config: {
    videoRecorderChoice: null,
    videoDeviceId: null,
    audioDeviceId: null,
    videoBitsPerSecond: null,
    audioBitsPerSecond: null,
    videoMimeType: null,
    videoIdealWidth: null,
    videoTransform: null,
    sendCameraCommands: false,
  },
  onValidate: null,
  defaultVideoUrl: null,
};

VideoRecorder.propTypes = {
  config: PropTypes.shape({
    videoRecorderChoice: PropTypes.string,
    videoDeviceId: PropTypes.string,
    audioDeviceId: PropTypes.string,
    videoBitsPerSecond: PropTypes.string,
    audioBitsPerSecond: PropTypes.string,
    videoMimeType: PropTypes.string,
    videoIdealWidth: PropTypes.string,
    videoTransform: PropTypes.string,
    sendCameraCommands: PropTypes.bool,
  }),
  setVideoRecorderChoice: PropTypes.func.isRequired,
  setLastMediaPermissionAllowDate: PropTypes.func.isRequired,
  onValidate: PropTypes.func,
  defaultVideoUrl: PropTypes.string,
  t: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    videoContainer: PropTypes.string.isRequired,
  }).isRequired,
};

export default withTranslation()(withStyles(classStyles)(VideoRecorder));
