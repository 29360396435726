import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import ButtonsContainer from '../BottomButtons/ButtonsContainer';
import NextButton from '../BottomButtons/NextButton';
import NextButtonSecondary from '../BottomButtons/NextButtonSecondary';
import yellowCircleImg from './images/yellowCircle.svg';
import redCircleImg from './images/redCircle.svg';
import blueCircleImg from './images/blueCircle.svg';
import './UserRecord.css';

const styles = theme => ({
  choiceText: {
    position: 'relative',
    marginTop: '8vh',
    paddingBottom: 'min(120px, 10vh)',
    marginBottom: '8vh',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 50,
    },
    '@media (orientation: landscape)': {
      fontSize: '2vh',
      marginTop: '3vh',
    },
  },
  yellowCircle: {
    position: 'absolute',
    top: 0,
    left: '30%',
    width: 'min(16%, 18vh)',
    [theme.breakpoints.down('sm')]: {
      left: '40%',
    },
  },
  redCircle: {
    position: 'absolute',
    bottom: 0,
    left: '18%',
    width: 'min(16%, 18vh)',
    [theme.breakpoints.down('sm')]: {
      left: '28%',
    },
  },
  blueCircle: {
    position: 'absolute',
    bottom: 0,
    left: '43%',
    width: 'min(8%, 9vh)',
    [theme.breakpoints.down('sm')]: {
      left: '53%',
    },
  },
});

class VideoNewQuestion extends Component {
  skipRecord() {
    this.props.resetVideoQuestion();
    this.props.history.push('/informations');
  }

  goToNextStep() {
    this.props.history.push('/record');
  }

  render() {
    const { classes, config } = this.props;

    return (
      <>
        <div className={classes.choiceText}>
          <Typography variant="h2" gutterBottom><Trans i18nKey="videoNewQuestion.choiceTitle" /></Typography>
          <Typography variant="h2" gutterBottom>
            {config?.clientName !== 'pmf' && <Trans i18nKey="videoNewQuestion.choiceQuestion" />}
            {config?.clientName === 'pmf' && <Trans i18nKey="videoNewQuestion.choiceQuestionPmf" />}
          </Typography>
          <img src={yellowCircleImg} alt="" className={classes.yellowCircle} />
          <img src={redCircleImg} alt="" className={classes.redCircle} />
          <img src={blueCircleImg} alt="" className={classes.blueCircle} />
        </div>

        <ButtonsContainer>
          <NextButton onClick={() => this.goToNextStep()}>
            <Trans i18nKey="videoNewQuestion.askMyQuestion" />
          </NextButton>
          <NextButtonSecondary onClick={() => this.skipRecord()}>
            <Trans i18nKey="videoNewQuestion.skipMyQuestion" />
          </NextButtonSecondary>
        </ButtonsContainer>
      </>
    );
  }
}

VideoNewQuestion.propTypes = {
  config: PropTypes.shape({
    clientName: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  resetVideoQuestion: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    choiceText: PropTypes.string.isRequired,
    yellowCircle: PropTypes.string.isRequired,
    redCircle: PropTypes.string.isRequired,
    blueCircle: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(withStyles(styles)(VideoNewQuestion));
