import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles/index';
import Typography from '@material-ui/core/Typography/index';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import HomepageUnescoContainer from '../../store/containers/HomepageUnescoContainer';
import frFlag from './images/frFlag.svg';
import gbFlag from './images/gbFlag.svg';
import homepageShape from './images/homepageShape.svg';
import HomepageBesanconContainer from '../../store/containers/HomepageBesanconContainer';
import HomepagePmfContainer from '../../store/containers/HomepagePmfContainer';

const styles = () => ({
  root: {
    height: '100%',
  },
  shape: {
    maxWidth: '90%',
    maxHeight: '60vh',
    width: '100%',
    margin: 'auto',
    marginTop: 20,
    '@media (orientation: landscape)': {
      maxHeight: '70vh',
    },
  },
  buttons: {
    marginTop: '5%',
  },
  flags: {
    width: '30%',
    margin: '2%',
    display: 'inline-block',
    cursor: 'pointer',
    '@media (orientation: landscape)': {
      width: '20%',
    },
  },
  flagsImg: {
    width: '100%',
  },
});

const StyledGrid = withStyles({
  container: {
    minHeight: '100%',
    textAlign: 'center',
  },
})(Grid);

const StyledGridItem = withStyles({
  root: {
    '@media (orientation: landscape)': {
      maxWidth: '50%',
      flexBasis: '50%',
    },
  },
})(Grid);

class Homepage extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  goToNexStep() {
    this.props.history.push('/videos');
  }

  async chooseLanguage(lang) {
    this.props.setLanguage(lang);
    this.goToNexStep();
  }

  render() {
    const { classes, config } = this.props;

    if (config?.clientName === 'unesco') {
      return <HomepageUnescoContainer />;
    }

    if (config?.clientName === 'besancon') {
      return <HomepageBesanconContainer />;
    }

    if (config?.clientName === 'pmf') {
      return <HomepagePmfContainer />;
    }

    return (
      <div className={classes.root}>
        <StyledGrid container justify="center" alignItems="center">
          <StyledGridItem item xs={12}>
            <img src={homepageShape} className={classes.shape} alt="" />
          </StyledGridItem>
          <StyledGridItem item xs={12}>
            <Typography variant="h6">
              <strong>Choose</strong> your language
              <br />
              <strong>Sélectionnez</strong> votre langue
            </Typography>
            <div className={classes.buttons}>
              <div
                role="button"
                onClick={() => this.chooseLanguage('fr')}
                onKeyPress={() => {
                }}
                tabIndex="0"
                className={classes.flags}
              >
                <img src={frFlag} className={classes.flagsImg} alt="Français" />
              </div>
              <div
                role="button"
                onClick={() => this.chooseLanguage('en')}
                onKeyPress={() => {
                }}
                tabIndex="0"
                className={classes.flags}
              >
                <img src={gbFlag} className={classes.flagsImg} alt="English" />
              </div>
            </div>
          </StyledGridItem>
        </StyledGrid>
      </div>
    );
  }
}

Homepage.propTypes = {
  config: PropTypes.shape({
    clientName: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  setLanguage: PropTypes.func.isRequired,
  onLoad: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    buttons: PropTypes.string.isRequired,
    shape: PropTypes.string.isRequired,
    flags: PropTypes.string.isRequired,
    flagsImg: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(withStyles(styles)(Homepage));
